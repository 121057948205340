import React from 'react';
import { motion } from 'framer-motion';
import { Code, Server, Database, Cloud, Wrench , Shield } from 'lucide-react';

const ServiceCard = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
  >
    <div className="text-blue-500 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </motion.div>
);

const Services = () => {
  return (
    <section id="services" className="py-20 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ServiceCard 
            icon={<Code size={40} />} 
            title="Frontend Development" 
            description="React, Vue, Next.js를 활용한 최신 웹 애플리케이션 개발"
          />
          <ServiceCard 
            icon={<Server size={40} />} 
            title="Backend Development" 
            description="Node.js, Express, GraphQL을 이용한 강력한 서버 구축"
          />
          <ServiceCard 
            icon={<Database size={40} />} 
            title="Database Management" 
            description="MySQL, Redis를 활용한 효율적인 데이터 관리"
          />
          <ServiceCard 
            icon={<Cloud size={40} />} 
            title="Cloud Solutions" 
            description="네이버 클라우드 플랫폼, Docker, Kubernetes를 이용한 클라우드 솔루션"
          />
          <ServiceCard 
            icon={<Wrench  size={40} />} 
            title="IoT & Embedded" 
            description="Raspberry Pi, MQTT를 활용한 IoT 솔루션 개발"
          />
          <ServiceCard 
            icon={<Shield size={40} />} 
            title="Security Solutions" 
            description="맞춤형 보안 솔루션 구현 및 컨설팅"
          />
        </div>
      </div>
    </section>
  );
};

export default Services;