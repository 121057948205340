import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Sun, Moon } from "lucide-react";
import { ThemeContext } from "../context/ThemeContext";

const Header = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <header className="sticky top-0 z-50 backdrop-blur-lg bg-white/75 dark:bg-gray-800/75 shadow-md">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl font-bold"
        >
          Guriout
        </motion.div>
        <ul className="flex space-x-4">
          <li>
            <a
              href="#services"
              className="hover:text-blue-500 transition-colors"
            >
              서비스
            </a>
          </li>
          <li>
            <a
              href="#portfolio"
              className="hover:text-blue-500 transition-colors"
            >
              포트폴리오
            </a>
          </li>
          {/* <li>
            <a href="#team" className="hover:text-blue-500 transition-colors">
              팀
            </a>
          </li> */}
          <li>
            <a
              href="#contact"
              className="hover:text-blue-500 transition-colors"
            >
              문의하기
            </a>
          </li>
          {/* <li>
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
            >
              {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>
          </li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
