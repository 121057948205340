import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AnimatePresence } from 'framer-motion';
import { ThemeContext } from './context/ThemeContext';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Review from './components/Review';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' || (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <AnimatePresence>
        <Helmet>
          <title>Guriout - 혁신적인 웹 및 모바일 솔루션</title>
          <meta name="description" content="Guriout은 최첨단 웹 및 모바일 애플리케이션을 전문으로 하는 한국의 개발 팀입니다. React, Node.js, IoT 등 다양한 기술 스택을 활용한 맞춤형 솔루션을 제공합니다." />
          <meta name="keywords" content="웹 개발, 모바일 앱, React, Node.js, IoT, 한국 개발팀, 외주 개발" />
          <link rel="canonical" href="https://www.guriout.com" />
          <html lang="ko" />
        </Helmet>
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-300">
          <Header />
          <main>
            <Hero />
            <Services />
            <Portfolio />
            <Review />
            <Contact />
          </main>
          <Footer />
        </div>
      </AnimatePresence>
    </ThemeContext.Provider>
  );
};

export default App;