import React from "react";
import { motion } from "framer-motion";

const ProjectCard = ({ title, description, image }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
  >
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-6">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  </motion.div>
);

const Portfolio = () => {
  const projects = [
    {
      title: "E-commerce Platform",
      description: "React와 Node.js를 활용한 현대적인 온라인 쇼핑몰",
      image: "/api/placeholder/800/600",
    },
    {
      title: "IoT Home Automation",
      description: "Raspberry Pi와 MQTT를 이용한 스마트홈 시스템",
      image: "/api/placeholder/800/600",
    },
    {
      title: "AI-powered Analytics Dashboard",
      description: "Python과 TensorFlow를 활용한 데이터 분석 대시보드",
      image: "/api/placeholder/800/600",
    },
    {
      title: "Blockchain-based Supply Chain",
      description: "이더리움을 활용한 투명한 공급망 추적 시스템",
      image: "/api/placeholder/800/600",
    },
  ];

  return (
    <section id="portfolio" className="py-20 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Portfolio</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
