import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const reviews = [
  {
    id: 1,
    name: "주식회사 테크노바",
    reviewer: "김**",
    content: "Guriout과 협업하면서 우리 스타트업의 생산성이 크게 향상되었습니다. 그들의 기술력과 열정에 감탄했습니다.",
    rating: 5,
  },
  {
    id: 2,
    name: "글로벌전자(주)",
    reviewer: "이사 박**",
    content: "대규모 디지털 전환 프로젝트를 Guriout과 함께 진행했는데, 결과물이 기대 이상이었습니다. 전문성과 책임감이 돋보였습니다.",
    rating: 5,
  },
  {
    id: 3,
    name: "스마트팩토리솔루션즈",
    reviewer: "공장장 최**",
    content: "Guriout의 IoT 솔루션 덕분에 우리 공장의 효율성이 20% 이상 개선되었습니다. 투자 대비 효과가 정말 뛰어납니다.",
    rating: 5,
  },
  {
    id: 4,
    name: "쇼핑몰닷컴",
    reviewer: "UX팀 장**",
    content: "우리 온라인 쇼핑몰의 사용자 경험을 Guriout이 혁신적으로 개선해주었습니다. 고객 피드백이 눈에 띄게 좋아졌어요.",
    rating: 5,
  },
  {
    id: 5,
    name: "세이프페이먼트(주)",
    reviewer: "CTO 윤**",
    content: "보안과 편의성을 모두 만족시키는 결제 시스템을 구축해준 Guriout에게 감사드립니다. 거래 성공률이 15% 상승했습니다.",
    rating: 5,
  },
];

const Review = () => {
  const [isHovered, setIsHovered] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollAnimation = () => {
      if (scrollRef.current && !isHovered) {
        scrollRef.current.scrollLeft += 1;
        if (
          scrollRef.current.scrollLeft >=
          scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        ) {
          scrollRef.current.scrollLeft = 0;
        }
      }
    };

    const animationId = setInterval(scrollAnimation, 30);

    return () => clearInterval(animationId);
  }, [isHovered]);

  return (
    <section id="reviews" className="py-20 bg-gradient-to-r from-blue-500 to-purple-600 relative overflow-hidden">
      <div className="absolute inset-0 backdrop-blur-xl bg-white/30 dark:bg-black/30"></div>
      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-4xl font-bold mb-12 text-center text-white">고객 리뷰</h2>
        <div className="relative">
          <div 
            ref={scrollRef}
            className="flex overflow-x-scroll scrollbar-hide -mx-4"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {reviews.map((review) => (
              <motion.div
                key={review.id}
                className="flex-shrink-0 w-80 mx-4 bg-white dark:bg-gray-800 bg-opacity-20 dark:bg-opacity-20 backdrop-blur-lg p-6 rounded-lg shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <h3 className="text-xl font-semibold mb-2 text-white">{review.name}</h3>
                <p className="text-gray-200 mb-4">{review.reviewer}</p>
                <p className="mb-4 text-white">{review.content}</p>
                <div className="flex items-center">
                  {[...Array(review.rating)].map((_, i) => (
                    <svg key={i} className="w-5 h-5 fill-current text-yellow-400" viewBox="0 0 24 24">
                      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-r from-blue-500 to-transparent pointer-events-none"></div>
          <div className="absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-l from-purple-600 to-transparent pointer-events-none"></div>
        </div>
      </div>
      <style jsx>{`
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </section>
  );
};

export default Review;